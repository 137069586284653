* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.az_laytout_99 {
  display: flex;
}

.az_main {
  width: calc(100% - 200px);
}

.MuiDrawer-paper,
.MuiDrawer-docked {
  width: 250px;
}

@media (max-width: 900px) {
  .az_main {
    width: 100%;
  }
}
